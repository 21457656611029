import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Button } from "react-bootstrap"

import { FaInfoCircle } from 'react-icons/fa';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1><FaInfoCircle />{` `} About</h1>
    <p>Some useful info...</p>
    <Button href="/" variant="light" size="sm">
      Back to Home
  </Button>
  </Layout>
)

export default AboutPage
